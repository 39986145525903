angular.module('vdsr').controller('lostpwController', 
['$scope', 'ajax', '$window', function($scope, ajax, $window){

	$scope.logoObj = $window.stw.globals.companyImages.logos.loginlogo;
	$scope.getLogoSizeConstraints = function() {
		var imageTypeObj = $scope.logoObj;
		var props = ["width", "height", "maxWidth", "maxHeight"];
		var style = {};
		angular.forEach(props, function(v) {
			if (imageTypeObj[v]) {
				var snake_case = v.replace(/([A-Z])/g, "-$1").toLowerCase();
				style[snake_case] = imageTypeObj[v];// + "px";
			}
		});
		return style;
	};
	
	$scope.message = undefined;

	$scope.lostpwSubmit = function(){
		var dat = $scope.lostpw;
		dat.categories = 'user';
      $scope.processing = true;
		ajax({
			method: 'post',
			url: '/lostpw',
			data: $scope.lostpw
		}).fork(function(e){
			$scope.message = "There was an error processing your request!";
			$scope.errorType = true;
         $scope.processing = false;
		}, function(r){
			$scope.message = r.data;
			$scope.errorType = false;
         $scope.processing = false;
		});
	};
}]);
